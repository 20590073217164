import * as React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import { ProductCollectionPage } from 'models/settings';
import { getImageUrl } from 'lib/util/imageUrl';
import { encodeCategoryNameForURI } from 'lib/util/encodeCategoryNameForURI';
import { appendQueryString } from 'lib/util/appendQueryString';
import { useQueryString } from 'hooks/useQueryString';

import baseStyles from '../../base.module.css';
import styles from './CategoryCard.module.css';

type Props = {
  category: ProductCollectionPage;
};

export const CategoryCard = ({ category }: Props) => {
  const { t } = useTranslation();

  const queryString = useQueryString();

  return (
    <li className={styles['item__li']}>
      <Link
        href={appendQueryString(`/top/${encodeCategoryNameForURI(category.name)}`, queryString)}
        className={styles['item__li__pic']}
      >
        {category.thumbnail_image_url && (
          <Image
            layout="fill"
            src={category.thumbnail_image_url}
            loader={({ src, width }) => getImageUrl(src, width)}
            alt="banner"
          />
        )}
      </Link>
      <div className={styles['item__li__info']}>
        <h2 className={clsx(styles['item__li__info__ttl'], 'maincolor-txt')}>
          {category.display_name || category.name}
        </h2>

        <p className={styles['item__li__info__desc']}>{category.description}</p>
        <Link
          href={appendQueryString(`/top/${encodeCategoryNameForURI(category.name)}`, queryString)}
          className={clsx(
            styles['item__li__info__btn'],
            baseStyles['color-txt--white'],
            baseStyles['color-bg--black']
          )}
        >
          <p>{t('VIEW MORE')}</p>
          <span className={baseStyles['color-bor--white']}></span>
        </Link>
      </div>
    </li>
  );
};
