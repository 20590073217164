import { PMPCategoryListViewProps } from 'lib/themes/themes';

import baseStyles from '../base.module.css';

import { CategoryCard } from './CategoryCard/CategoryCard';

const PMPCategoryListView = ({ categories }: PMPCategoryListViewProps) => {
  return (
    <div className={baseStyles['base-inner']}>
      <ul>
        {categories.map((category, idx) => (
          <CategoryCard key={idx} category={category} />
        ))}
      </ul>
    </div>
  );
};

export default PMPCategoryListView;
